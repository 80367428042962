<template>
  <tableau view="Patient_debts_available_to/Patient_debts" :inject-doctor-name="true" doctor-param-name="available_to_2" :inject-user-id="true" />
</template>

<script>
import Tableau from "./Tableau"

export default {
  components: { Tableau }
}
</script>
